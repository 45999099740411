const committeeColumns = [
    { id: 'code', label: 'Code' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'village_tract_name', label: 'Village Tract' },
    { id: 'mm_committee_name', label: 'Committee Name (MM)' },
    { id: 'en_committee_name', label: 'Committee Name (EN)' },
    { id: 'username', label: 'Phone No' },
    { id: 'point_amount', label: 'Credit Point Amount' },
    { id: 'cash_amount', label: 'Cash Amount' },
    { id: 'lost_rate_percent', label: 'Lost Rate (%)' },
    { id: 'status', label: 'Status' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const memberColumns = [
    { id: 'member_id', label: 'Member ID' },
    { id: 'mm_name', label: 'Name (MM)' },
    { id: 'nrc_no', label: 'NRC No' },
    { id: 'phone_no', label: 'Phone No' },
    { id: 'gender', label: 'Gender' },
    { id: 'is_disabled', label: 'Is Disabled' },
    { id: 'age', label: 'Age' },
    { id: 'date_of_birth', label: 'Date Of Birth' },
    { id: 'position_name', label: 'Position' },
    { id: 'farm_type_name', label: 'Farm Type' },
    { id: 'crop_type_name', label: 'Crop Type' },
    { id: 'own_no_of_acre', label: 'No of Acre (Own)' },
    { id: 'rent_no_of_acre', label: 'No of Acre (Rent)' },
    { id: 'committee_name', label: 'Committee' },
    { id: 'funded_amount', label: 'Funded Amount' },
    { id: 'point_amount', label: 'Credit Point Amount' },
    { id: 'status', label: 'Status' },
    { id: 'village_mimu_code', label: 'Village Mimu Code' },
    { id: 'village_name', label: 'Village' },
    { id: 'villageTract_name', label: 'Village Tract' },
    { id: 'township_name', label: 'Township' },
    { id: 'region_name', label: 'Region' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const shopColumns = [
    { id: 'mm_name', label: 'Name (MM)' },
    { id: 'en_name', label: 'Name (EN)' },
    { id: 'username', label: 'Phone No' },
    { id: 'address', label: 'Address' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'office_name', label: 'Office' },
    { id: 'credit_point', label: 'Credit Point Amount' },
    { id: 'earned_amount', label: 'Earned Amount' },
    { id: 'commision_rate', label: 'Commission Rate' },
    { id: 'is_active', label: 'Is Active' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const depositContractColumns = [
    { id: 'ref_id', label: 'Ref ID' },
    { id: 'committee_name', label: 'Committee' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'village_tract_name', label: 'Village Tract' },
    { id: 'office_name', label: 'Office' },
    { id: 'credit_point', label: 'Credit Point Amount' },
    { id: 'cash_amount', label: 'Cash Amount' },
    { id: 'received_method', label: 'Received Method' },
    { id: 'exchange_rate', label: 'Exchange Rate' },
    { id: 'deposit_date', label: 'Deposit Date' },
    { id: 'next_deposit_date', label: 'Next Deposit Date' },
    { id: 'cash_collection_date', label: 'Cash Collection Date' },
    { id: 'subscription_per_member', label: 'Subscription per member' },
    { id: 'amount', label: 'Total Subscription Amount' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const loanContractColumns = [
    { id: 'ref_id', label: 'Ref ID' },
    { id: 'committee_name', label: 'Committee' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'village_tract_name', label: 'Village Tract' },
    { id: 'lent_credit_point', label: 'Lent Credit Point Amount' },
    { id: 'get_back_credit_point', label: 'Get Back Credit Point' },
    { id: 'lost_rate_percent', label: 'Lost Rate Percent' },
    { id: 'cash_collection_date', label: 'Cash Collection Date' },
    { id: 'status', label: 'Status' },
    { id: 'is_closed', label: 'Is Closed' },
    { id: 'total_members', label: 'Total Members' },
    { id: 'close_date', label: 'Closed Date' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const settlementColumns = [
    { id: 'settlement_id', label: 'Ref ID' },
    { id: 'shop_name', label: 'Shop' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'office_name', label: 'Office' },
    { id: 'settled_amount', label: 'Settled Amount' },
    { id: 'commission_amount', label: 'Commission Amount' },
    { id: 'settled_date', label: 'Settled Date' },
    { id: 'status', label: 'Status' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const fundingTransferColumns = [
    { id: 'ref_id', label: 'Ref ID' },
    { id: 'donor_name', label: 'Donor' },
    { id: 'committee_name', label: 'Committee' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'village_tract_name', label: 'Village Tract' },
    { id: 'handover_amount', label: 'Transfer Amount' },
    { id: 'funding_handover_date', label: 'Transfer Date' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const fundReceivingInvoiceColumns = [
    { id: 'reference_number', label: 'Ref No' },
    { id: 'invoice_no', label: 'Invoice No' },
    { id: 'donor_name', label: 'Donor Name' },
    { id: 'funding_receiving_invoice_amount', label: 'Receive Amount' },
    { id: 'receive_date', label: 'Receive Date' },
    { id: 'fund_receiver_name', label: 'Receiver Name' },
    { id: 'created_date', label: 'Created Date' },
];

const fundAllocationColumns = [
    { id: 'id_number', label: 'Ref No' },
    { id: 'fund_allocated_total_amount', label: 'Allocated Amount' },
    { id: 'region_name', label: 'Region Name' },
    { id: 'office_name', label: 'Office Name' },
    { id: 'allocated_date', label: 'Allocated Date' },
    { id: 'description', label: 'Description' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const revenueTransfersColumns = [
    { id: 'transfer_id', label: 'Transfer Id' },
    { id: 'total_amount', label: 'Total Amount' },
    { id: 'office_en_name', label: 'Transfer From' },
    { id: 'status', label: 'Status' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_date', label: 'Created Date' },
];

const productColumns = [
    { id: 'product_code', label: 'Code' },
    { id: 'product_name', label: 'Name' },
    { id: 'manufacture', label: 'Company Name' },
    { id: 'category_name', label: 'Category' },
    { id: 'created_by', label: 'Created By' },
    { id: 'created_at', label: 'Created At' },
]

const invoiceListColumns = [
    { id: 'invoice_id', label: 'Invoice Id' },
    { id: 'created_date', label: 'Order Date' },
    { id: 'member_id', label: 'Member Id' },
    { id: 'member_name', label: 'Member Name' },
    { id: 'member_phone', label: 'Member Phone' },
    { id: 'shop_name', label: 'Shop Name' },
    { id: 'categoryEnName', label: 'Product Category' },
    { id: 'productCode', label: 'Product Code' },
    { id: 'productName', label: 'Product Name' },
    { id: 'qty', label: 'Qty'},
    { id: 'unitPrice', label: 'Unit Price'},
    { id: 'village_name', label: 'Village' },
    { id: 'villageTract_name', label: 'Village Tract' },
    { id: 'township_name', label: 'Township' },
    { id: 'region_name', label: 'Region' },
    { id: 'confirmDate', label: 'Invoice Date' },
];

const invoiceSummaryColumns = [
    { id: 'invoice_id', label: 'Invoice Id' },
    { id: 'created_date', label: 'Order Date' },
    { id: 'member_id', label: 'Member Id' },
    { id: 'member_name', label: 'Member Name' },
    { id: 'member_phone', label: 'Member Phone' },
    { id: 'shop_name', label: 'Shop Name' },
    { id: 'point_pay_amount', label: 'Paid by Point' },
    { id: 'cash_down_amount', label: 'Cash down Amount' },
    { id: 'discount_amount', label : "Discount Amount" },
    { id: 'total_amount', label: 'Total Amount' },
    { id: 'status', label: 'Invoice Status' },
    { id: 'village_name', label: 'Village' },
    { id: 'villageTract_name', label: 'Village Tract' },
    { id: 'township_name', label: 'Township' },
    { id: 'region_name', label: 'Region' },
    { id : 'confirmDate', label: 'Invoice Date'}
];

const articleListColumns = [
    { id: 'title', label: 'Article Title' },
    { id: 'category_names', label: 'Category' },
    { id: 'created_at', label: 'Created Date Time' },
    { id: 'view_count', label: 'Read Count' }
]


const ecommerceOrderSummaryColumns = [
    { id: 'order_id', label: 'Order Id' },
    { id: 'created_date', label: 'Date' },
    { id: 'customer_name', label: 'Customer' },
    { id: 'customer_phone', label: 'Customer Phone' },
    { id : "region_name", label : "Region"},
    { id: 'delivery_fees', label: 'Deliver Fees' },
    { id: 'payment_method', label: 'Payment method' },
    { id: 'description', label: 'Description' },
    { id: 'net_total', label: 'Total' },
    { id: 'status', label: 'Status' },
]

const ecommerceOrderColumns = [
    { id: 'order_id', label: 'Order Id' },
    { id: 'created_date', label: 'Order Date' },
    { id : 'user_name', label: "User Name"},
    { id : 'user_phone_no', label: "User Phone"},
    { id: 'customer_name', label: 'Customer' },
    { id: 'customer_phone', label: 'Customer Phone' },
    { id: 'region_name', label: 'Region' },
    { id: 'township_name', label: 'Township' },
    { id: 'address', label: 'Address' },
    { id: 'categoryEnName', label: 'Product Category(ENG)' },
    { id: 'categoryMMName', label: 'Product Category(MYA)' },
    { id: 'product_code', label: 'Product Code'},
    { id: 'productName', label: 'Product Name'},
    { id: 'manufacture', label: 'Company' },
    { id: 'qty', label: 'Qty' },
    { id: 'unitPrice', label: 'Unit Price' },
    { id: 'discount_amount', label: 'Discount Amount'},
    { id: 'totalPrice', label: 'Total Amount'},
    { id: 'delivery_fees', label: 'Deliver Fees' },
    { id: 'payment_method', label: 'Payment method' },
    { id: 'description', label: 'Description' },
    { id: 'status', label: 'Status' },
]

export {
    committeeColumns,
    memberColumns,
    shopColumns,
    productColumns,
    invoiceListColumns,
    articleListColumns,
    loanContractColumns,
    depositContractColumns,
    settlementColumns,
    fundingTransferColumns,
    fundReceivingInvoiceColumns,
    fundAllocationColumns,
    revenueTransfersColumns,
    invoiceSummaryColumns,
    ecommerceOrderColumns,
    ecommerceOrderSummaryColumns
}